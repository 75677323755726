import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ItalicHeading from "../components/ui/ItalicHeading"
import phone from '../images/contact-phone.svg'
import email from '../images/contact-email.svg'

const Wrapper = styled.div`
    padding: 60px 0;
    text-align: center;
    color: #6786FF;
    & p {
        margin: 70px auto 0;
    }
`
const ContactLineList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto 0;
`
const ContactsLine = styled.li`
    display: flex;
    align-items: center;
    margin: 20px auto 0;
`
const ContactsIcon = styled.img`
    height: 30px;
    width: 30px;
    margin-right: 20px;
`
const ContactsText = styled.div`
    & a {
        font-size: 14px;
        text-decoration: none;
        color: inherit;
    }
`

const Form = styled.form`
    position: relative;
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    @media (min-width: 834px) {
        width: 60%;
    }
`
const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #6786FF;
    border-radius: 2px;
    margin: 5px;
    padding: 20px;
    color: #6786FF;
    font-size: 14px;
    line-height: 18px;
    &::placeholder {
        color: #6786FF;
    }
`
const Message = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #6786FF;
    border-radius: 2px;
    margin: 5px;
    color: #6786FF;
    padding: 20px;
    font-size: 14px;
    line-height: 18px;
    &::placeholder {
        color: #6786FF;
    }
`
const ColumnInputs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    @media(min-width: 834px) {
        flex-direction: row;
        & ${Input} {
            flex: 1;
        }
    }
`
const Submit = styled.button`
    background: linear-gradient(90deg, #6786FF 6.2%, #85ABEE 100%);
    box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
    border-radius: 30px;
    border: none;
    color: #fff;
    padding: 13px 40px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    width: min-content;
    margin: 40px auto;

    &:disabled {
        cursor: none;
        background: #aaa;
    }
`

const Sending = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: .7;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Sent = styled.div`
    padding: 120px 0;
    h4 {
        font-style: italic;
        font-weight: 600;
        font-size: 40px;
        line-height: 55px;
        letter-spacing: -0.4px;
    }

    p {
        margin: 40px auto 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
`

const searchParams = params => Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
}).join('&');


const ContactPage = () => {

    const [fields, setFields] = useState({ first_name: '', last_name: '', email: '', message: '' })
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)

    const onInputChange = e => {

        e.persist()

        setFields(fields => ({ ...fields, [e.target.name]: e.target.value }))
    }

    const onSubmit = async e => {

        e.preventDefault()

        setSending(true)

        try { //TODO: api should return 200 when message is succesfull

            await fetch(process.env.GATSBY_CONTACT_URL, {
                method: 'POST',
                redirect: "manual", //TODO: remove this once the api returns a proper 200 code
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: searchParams(fields)
            })
        }
        catch (err) {

            console.log(err)
        }

        setSending(false)
        setSent(true)
    }

    return <Layout>
        <SEO title="Contact" />
        <Wrapper>
            <ItalicHeading>Contact Us</ItalicHeading>
            <p>Office Hours:<br /><br />9 AM - 6 PM PST (Mon - Fri)</p>
            <ContactLineList>
                <ContactsLine>
                    <ContactsIcon src={phone} />
                    <ContactsText><a href="tel:(415) 617 - 9894">(415) 617 - 9894</a></ContactsText>
                </ContactsLine>
                <ContactsLine>
                    <ContactsIcon src={email} />
                    <ContactsText><a href="mailto:support@getcerebral.com">support@getcerebral.com</a></ContactsText>
                </ContactsLine>
            </ContactLineList>

            {!sent &&
                <p>You can also leave us a message below and someone from our team will get back to you as soon as possible.</p>
            }

            <Form onSubmit={onSubmit}>
                {sending &&
                    <Sending>Loading...</Sending >
                }

                {sent &&
                    <Sent>
                        <h4>Message Sent.</h4>
                        <p>Thank you for reaching out to us. Someone from the Cerebral team will get back to you as soon as possible. Just check your inbox for updates.</p>
                    </Sent>
                }

                {!sent &&
                    <>
                        <ColumnInputs>
                            <Input name="first_name" placeholder="First name" value={fields.first_name} onChange={onInputChange} required />
                            <Input name="last_name" placeholder="Last name" value={fields.last_name} onChange={onInputChange} />
                        </ColumnInputs>
                        <Input name="email" placeholder="Email" value={fields.email} onChange={onInputChange} type="email" required />
                        <Message name="message" placeholder="Type your message here..." rows="4" value={fields.message} onChange={onInputChange} required />
                        <Submit type="submit" disabled={sending}>Submit</Submit>
                    </>
                }
            </Form>
        </Wrapper>
    </Layout >
}

export default ContactPage